<template>
  <ModalWrapper @close="onClose">
    <div
      class="inline-block align-bottom bg-gray-0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full relative"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <button class="absolute right-5 top-5" @click="onClose">
        <img src="@/assets/icons/x.svg" alt="close" class="w-8 h-8" />
      </button>
      <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-col  space-y-5">
        <div class="flex items-center text-24">
          Make an offer
        </div>
      </div>

      <div class="w-full border-b border-blue-450"></div>

      <div
        class="pt-5 pb-4 sm:py-6 text-center flex flex-col items-center space-y-8"
      >
        <form v-if="step === 1" class="w-full flex flex-col space-y-5">
          <div class="space-y-10 px-5 sm:px-10">
            <div class="flex justify-around">
              <div class="text-16">
                Total Balance
                <div class="text-32">{{ balance }}</div>
              </div>
              <div class="text-16">
                Highest Bid
                <div class="text-32">{{ highestValueDisplay }}</div>
              </div>
            </div>
            <div class="text-center">
              Your offer
              <t-input
                id="offer"
                ref="offer"
                v-model="offer"
                type="number"
                :disabled="!hasfunds"
              >
              </t-input>
              <p class="text-red-500">{{ error }}</p>
            </div>
          </div>

          <div v-if="hasfunds" class="w-full border-b border-blue-450"></div>

          <div v-if="hasfunds" class="text-left px-5 sm:px-10 text-14">
            <p>Before you submit:</p>
            <br />
            <p>Your offer will be held in escrow.</p>
            <p>
              The funds will be released if your bid is outbid.
            </p>
            <p>
              You will receive the NFT after the offer is accepted by the owner.
            </p>
          </div>

          <div class="w-full border-b border-blue-450"></div>

          <div class="flex flex-col items-center space-y-4">
            <div class="max-w-max mx-auto">
              <t-button
                v-if="hasfunds"
                ref="buyButton"
                @click.prevent="buyToken"
              >
                Make offer
              </t-button>
              <t-button v-else ref="depositButton" @click="openDepositModal">
                Deposit {{ token.currency }}
              </t-button>
            </div>
          </div>
        </form>
        <ApproveActionModal
          v-else
          :confirmation-id="confirmation_id"
          @close="onClose"
        />
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import * as api from '@/api';

import ModalWrapper from '@/components/modals/ModalWrapper';
import ApproveActionModal from '@/components/modals/ApproveActionModal';
import { formatCrypto } from '@/utils/decimal';

import eventBus from '@/utils/eventBus';

export default {
  name: 'SignInModal',
  components: {
    ApproveActionModal,
    ModalWrapper,
  },
  props: {
    token: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      offer: 0,
      error: '',
      confirmation_id: '',
      hasfunds: false,
      balance: 0,
    };
  },
  computed: {
    highestValueDisplay() {
      return this.token.highest_bid
        ? formatCrypto(this.token.highest_bid, this.token.currency)
        : '-';
    },
  },
  async mounted() {
    this.$refs.offer.focus();
    await this.fetchBalance();
  },

  methods: {
    async fetchBalance() {
      const balances = await api.get('balances');
      const balance = balances[this.token.currency];
      this.hasfunds = balance > 0;
      this.balance = formatCrypto(balance, this.token.currency);
    },
    async buyToken() {
      this.error = '';

      try {
        const { confirmation_id } = await api.post(
          `tokens/${this.token.id}/bid`,
          {
            amount: this.offer,
          },
        );
        this.confirmation_id = confirmation_id;
        this.step++;
      } catch (e) {
        if (e.response.data.amount) {
          this.error = (e.response.data.amount || []).join(' ');
          if (e.response.data.amount[0] === 'Not enough money.') {
            this.hasfunds = false;
          }
        } else if (e.response.data.non_field_errors) {
          this.error = (e.response.data.non_field_errors || []).join(' ');
        } else if (e.response.status === 403 && e.response.data.detail) {
          this.error = e.response.data.detail;
        } else {
          this.error = (e.response.data || []).join(' ');
        }
      }
    },
    openDepositModal() {
      this.onClose();
      eventBus.$emit('open-deposit-modal', this.token.currency);
    },

    onClose() {
      this.$emit('close');
    },
  },
};
</script>
