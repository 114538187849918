<template>
  <div
    v-infinite-scroll="fetchMoreHistory"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="80"
    :class="{ 'mt-10': !tokenId }"
    class="mt-5 mb-28"
  >
    <h2 v-if="isData" class="text-32 font-bold mb-3">
      Transaction History
    </h2>
    <table
      v-if="!renderResponsive && isData"
      class="w-full table-fixed divide-y divide-gray-200 shadow-sm border-gray-200 border text-14"
    >
      <thead class="divide-y divide-gray-200 ">
        <th
          v-for="(header, headerIndex) in headers"
          :key="headerIndex"
          class="px-3 py-3 text-gray-800 text-12 leading-4 font-medium tracking-wider text-left bg-gray-50 uppercase
          "
        >
          {{ header }}
        </th>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200 border-l border-r">
        <tr v-for="(row, rowIndex) in data" :key="rowIndex" class="relative">
          <td
            v-for="(header, headerIndex) in headers"
            :key="rowIndex + ' ' + headerIndex"
            :class="{ capitalize: header !== 'Who' && header !== 'Item' }"
            class="h-16 lg:h-14 pt-5 pb-3 px-3 whitespace-no-wrap "
          >
            <template
              v-if="header === 'Item'"
              class="align-top pt-3 pb-3 px-3 whitespace-no-wrap truncate h-16 lg:h-14 relative"
            >
              <div
                class="flex h-9 max-w-min cursor-pointer"
                @click="redirectToDetails(row.assetID)"
              >
                <div class="block box-10 h-10 w-10 overflow-hidden">
                  <img
                    :src="row.itemImg"
                    alt="nft token"
                    class="object-cover pointer-events-none "
                  />
                </div>
                <p class="ml-2 whitespace-nowrap my-auto truncate">
                  {{ row.itemName }}
                </p>
              </div>
            </template>
            <template v-else>{{ row[header] }}</template>
          </td>
        </tr>
      </tbody>
    </table>
    <table
      v-if="renderResponsive && !isLoading"
      class="w-full table-fixed border-none"
    >
      <tbody>
        <template v-for="(row, rowIndex) in data">
          <tr
            v-for="(header, headerIndex) in headers"
            :key="rowIndex + ' ' + headerIndex"
            class="border-2"
          >
            <td class="px-2 py-2 w-28">
              <strong>{{ header }}</strong>
            </td>
            <td
              :class="{ capitalize: header !== 'Who' && header !== 'Item' }"
              class="truncate block px-2 py-2 w-100"
            >
              <template
                v-if="header === 'Item'"
                class="truncate block px-2 py-2 w-100"
              >
                <div
                  class="flex h-9 items-center cursor-pointer"
                  @click="redirectToDetails(row.assetID)"
                >
                  <div class="block box-10 h-10 w-10 overflow-hidden">
                    <img
                      :src="row.itemImg"
                      alt="nft token"
                      class="object-cover pointer-events-none "
                    />
                  </div>
                  <p class="ml-2 text-14 whitespace-nowrap truncate">
                    {{ row.itemName }}
                  </p>
                </div>
              </template>
              <template v-else>{{ row[header] }}</template>
            </td>
          </tr>

          <tr
            v-if="rowIndex < data.length - 1"
            :key="`${rowIndex}_`"
            class="h-10 border-r-0 border-l-0"
          >
            <td class="w-min" />
            <td class="w-100" />
          </tr>
        </template>
      </tbody>
    </table>
    <Loader v-if="isLoading" class="mx-auto w-24 h-24 mt-10" />
  </div>
</template>
<script>
import * as api from '@/api';
import { formatDistanceToNowStrict } from 'date-fns';
import Loader from '@/components/Loader';
import mapTransactionKind from '@/utils/mapTransactionKind';
import { formatCrypto } from '@/utils/decimal';
import capitalize from '@/utils/capitalize';
import redirect from '@/utils/redirect';

const TOKEN_HISTORY_REFRESH_INTERVAL_SECONDS = 3;

export default {
  name: 'TokenHistoryTable',
  components: {
    Loader,
  },
  props: {
    tokenId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      busy: false,
      lastPage: 0,
      hasMore: true,
      timer: null,
      openRows: [],
      asset: '',
      type: '',
      data: [],
      headers: ['Transaction type', 'Who', 'Price', 'When', 'State'],
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    renderResponsive() {
      const { windowWidth } = this;
      return windowWidth && windowWidth < 768;
    },
    isData() {
      return !!this.data.length;
    },
  },
  async mounted() {
    if (!this.tokenId) {
      this.headers = ['Transaction type', 'Who', 'Price', 'Item', 'When'];
    }
    await this.fetchHistory(1);
    this.timer = setInterval(
      () => this.fetchHistory(1),
      TOKEN_HISTORY_REFRESH_INTERVAL_SECONDS * 1000,
    );
    window.addEventListener('resize', this.resizeListener);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener);
    clearInterval(this.timer);
  },
  methods: {
    redirectToDetails(id) {
      redirect({
        name: 'TokenDetails',
        params: {
          id,
        },
      });
    },
    resizeListener() {
      this.windowWidth = window.innerWidth;
    },
    async fetchMoreHistory() {
      if (!this.hasMore) return;
      // Disable autorefresh
      clearInterval(this.timer);
      this.fetchHistory(this.lastPage + 1);
    },
    async fetchHistory(page) {
      if (this.busy) return;
      if (page !== 1 || this.lastPage === 0) {
        this.isLoading = true;
      }
      this.busy = true;

      const { results, count } = await api.get('events', {
        page,
        token: this.tokenId,
      });

      const data = results.map(event => {
        const {
          amount,
          created_at,
          currency,
          id,
          username,
          kind,
          state,
          token,
        } = event;
        const timePeriod = formatDistanceToNowStrict(new Date(created_at), {
          addSuffix: true,
        });

        return {
          id,
          'Transaction type': mapTransactionKind(kind),
          Price: formatCrypto(amount, currency),
          Who: username,
          When: timePeriod,
          State: capitalize(state),
          assetID: token.id,
          itemName: token.name,
          itemImg: token.asset_medium,
        };
      });

      if (page === 1) {
        this.data = data;
      } else {
        this.data = this.data.concat(data);
      }
      this.hasMore = count > this.data.length;
      this.busy = false;
      this.isLoading = false;
      this.lastPage = page;
    },
  },
};
</script>
<style scoped>
.td-max-height-135 {
  max-height: 135px;
  overflow: hidden;
}
.box-10 {
  min-width: 40px !important;
  min-height: 40px !important;
}
</style>
