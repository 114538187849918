export default function mapTransactionKind(transactionKind) {
  const offer = {
    BUY_NFT: 'NFT Purchase',
    DEPOSIT: 'Deposit',
    BID: 'Offer',
    WITHDRAWAL: 'Withdrawal',
    SALE: 'Sale',
    FOR_SALE: 'For Sale',
  };

  return offer[transactionKind];
}
