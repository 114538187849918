<template>
  <div>
    <BuyNowModal
      v-if="isOpenBuyNow"
      :token="token"
      @close="closeBuyNowModal"
      @refresh="refresh"
    />
    <BidModal
      v-if="isOpenBidModal"
      :token="token"
      @close="closeBidModal"
      @refresh="refresh"
    />
    <BidAcceptModal
      v-if="isOpenBidAcceptModal && token.is_owned_by_me"
      :token="token"
      @close="closeBidAcceptModal"
    />
    <BidCancelModal
      v-if="isOpenBidCancelModal && token.is_my_bid_winning"
      :token="token"
      @close="closeBidCancelModal"
    />
    <TokenUpdateModal
      v-if="isOpenUpdateModal && token.is_owned_by_me"
      :token="token"
      @close="closeUpdateModal"
    />
    <PublishModal
      v-if="isOpenPublishModal && token.is_owned_by_me"
      :token="token"
      @close="closePublishModal"
    />
    <UnPublishModal
      v-if="isOpenUnPublishModal && token.is_owned_by_me"
      :token="token"
      @close="closeUnPublishModal"
    />

    <div
      class="relative bg-gray-750 header flex flex-col justify-center items-center"
    >
      <div class="sm:w-96 w-1/2">
        <TopImage :image="token.asset" />
      </div>
      <div class="absolute bottom-5 md:right-40 right-20 space-x-3 flex">
        <div
          class="bg-gray-700 text-gray-400 rounded-lg w-8 h-8 cursor-pointer flex items-center justify-center"
          @click="copyLink"
        >
          <img
            v-if="showShareIcon"
            src="@/assets/icons/share.svg"
            alt="copy link"
          />
          <img
            v-else
            src="@/assets/icons/check_gray.svg"
            alt="copy confirmation"
          />
        </div>
        <a
          :href="token.asset"
          class="bg-gray-700 rounded-lg w-8 h-8 cursor-pointer flex justify-center items-center"
        >
          <img src="@/assets/icons/expand_img.svg" alt="image full screen" />
        </a>
      </div>
    </div>

    <div class="md:mx-20 px-10 mt-3">
      <div
        class="mt-10 text-gray-500 text-14 leading-5 font-medium flex space-x-5 items-baseline"
      >
        <router-link to="/">
          <img src="@/assets/icons/home.svg" alt="home" class="home-img"
        /></router-link>

        <img src="@/assets/icons/arrow_right.svg" alt="arrow right " />
        <router-link to="/">
          Marketplace
        </router-link>
        <img src="@/assets/icons/arrow_right.svg" alt="arrow right" />

        <router-link :to="navLink" class="truncate">
          {{ navLabel }}
        </router-link>
      </div>
      <div class="grid grid-cols-1 pb-20 lg:grid-cols-3 lg:space-x-10">
        <div class="col-span-2">
          <div
            class="text-6xl leading-none font-extrabold tracking-tight py-10 max-w-80-screen break-words"
          >
            {{ token.name }}
          </div>
          <div class="text-18 leading-7 font-normal text-gray-500 break-words">
            {{ token.description }}
          </div>
        </div>

        <div>
          <div
            v-if="token.is_owned_by_me"
            class="mb-6 grid gap-2 grid-cols-2 grid-rows-1"
          >
            <t-button
              v-if="token.state === 'FOR_SALE'"
              :disabled="!Number(token.highest_bid)"
              @click="openBidAcceptModal"
            >
              Accept offer
            </t-button>

            <t-light-button
              v-if="token.state === 'FOR_SALE'"
              @click="openUnPublishModal"
            >
              Cancel listing
            </t-light-button>
            <t-button
              v-if="token.state === 'CREATED' || token.state === 'SOLD'"
              @click="openPublishModal"
            >
              Sell
            </t-button>

            <t-light-button
              v-if="token.state === 'CREATED'"
              @click="openUpdateModal"
            >
              Edit
            </t-light-button>
          </div>

          <div v-else class="mb-6 grid gap-2 grid-cols-2 grid-rows-1">
            <t-button
              :disabled="user.email !== '' && user.state !== 'KYC_PASS'"
              @click="openBuyNow"
            >
              Buy now
            </t-button>

            <t-light-button
              v-if="token.is_my_bid_winning"
              @click="openBidCancelModal"
            >
              Cancel offer
            </t-light-button>

            <t-light-button
              v-else
              :disabled="user.email !== '' && user.state !== 'KYC_PASS'"
              @click="openBid"
            >
              Make offer
            </t-light-button>
          </div>

          <div
            v-if="token && token.state === 'FOR_SALE'"
            class="border border-gray-200 rounded-md px-4 py-6 price"
          >
            <dl
              class="grid grid-cols-2 overflow-hidden divide-indigo-200 divide-y-0 divide-x"
            >
              <div class="pr-1 flex flex-col justify-between">
                <dt class="text-14 leading-5 font-medium text-gray-600 mb-2">
                  Price
                </dt>
                <dd
                  class="text-2xl leading-8 font-extrabold tracking-tight text-gray-800 my-auto break-words"
                >
                  {{ priceDisplay }}
                </dd>
              </div>
              <div class="px-1 flex flex-col justify-between pl-4">
                <dt class="text-14 leading-5 font-medium text-gray-600 mb-2">
                  Highest Bid
                </dt>
                <dd
                  class="text-24 leading-8 font-light tracking-tight text-gray-800 mt-0 mb-auto break-words"
                >
                  {{ highestValueDisplay }}
                </dd>
              </div>
            </dl>
          </div>

          <div class=" mt-6 p-1.5">
            <span
              v-for="tag in token.tags"
              :key="tag"
              class="items-center inline-block py-1.5 px-2 text-gray-50 bg-blue-450 rounded-lg max-w-max min-w-min mr-2 mb-2 break-words"
            >
              {{ tag }}
            </span>
          </div>
        </div>
      </div>
      <TokenHistoryTable v-if="token.id" :key="tableKey" :token-id="token.id" />
    </div>
  </div>
</template>
<script>
import TopImage from '@/components/TopImage';
import BuyNowModal from '@/components/modals/BuyNowModal';
import BidModal from '@/components/modals/BidModal';
import BidAcceptModal from '@/components/modals/BidAcceptModal';
import BidCancelModal from '@/components/modals/BidCancelModal';
import TokenUpdateModal from '@/components/modals/TokenUpdateModal';
import PublishModal from '@/components/modals/PublishModal';
import UnPublishModal from '@/components/modals/UnPublishModal';
import TokenHistoryTable from '@/components/TokenHistoryTable';

import { formatCrypto } from '@/utils/decimal';
import { mapGetters } from 'vuex';
import * as api from '@/api';
import { emitError } from '@/utils/alerts';
import eventBus from '@/utils/eventBus';

const TOKEN_REFRESH_INTERVAL_SECONDS = 3;

export default {
  name: 'TokenDetails',
  components: {
    TopImage,
    BuyNowModal,
    BidModal,
    BidAcceptModal,
    BidCancelModal,
    TokenUpdateModal,
    PublishModal,
    UnPublishModal,
    TokenHistoryTable,
  },
  data() {
    return {
      token: {},
      showShareIcon: true,
      isOpenBuyNow: false,
      isOpenBidModal: false,
      isOpenBidAcceptModal: false,
      isOpenBidCancelModal: false,
      isOpenUpdateModal: false,
      isOpenPublishModal: false,
      isOpenUnPublishModal: false,
      tableKey: false,
      timer: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
    highestValueDisplay() {
      return this.token.highest_bid
        ? formatCrypto(this.token.highest_bid, this.token.currency)
        : '-';
    },
    priceDisplay() {
      return this.token.price
        ? formatCrypto(this.token.price, this.token.currency)
        : '-';
    },
    navLabel() {
      return this.token.collection;
    },
    navLink() {
      return '/collections/' + this.token.collection;
    },
    tokenId() {
      return this.$route.params.id;
    },
  },
  watch: {
    tokenId() {
      this.refresh();
    },
  },
  async mounted() {
    await this.fetchToken();
    this.timer = setInterval(
      () => this.fetchToken(),
      TOKEN_REFRESH_INTERVAL_SECONDS * 1000,
    );
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    async refresh() {
      await this.fetchToken();
      this.tableKey = !this.tableKey;
    },
    openBuyNow() {
      if (!this.user.email) {
        eventBus.$emit('show-sign-in-modal');
      } else {
        this.openBuyNowModal();
      }
    },
    openBid() {
      if (!this.user.email) {
        eventBus.$emit('show-sign-in-modal');
        return;
      }
      this.openBidModal();
    },

    async fetchToken() {
      this.token = await api.get(`tokens/${this.tokenId}`);
    },

    async acceptBid() {
      try {
        await api.post(`tokens/${this.token.id}/actions`, {
          action_type: 'ACCEPT_BID',
        });
      } catch (e) {
        console.error(e);
        emitError("Couldn't accept offer. Try again later.");
      }
    },

    copyLink() {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          this.showShareIcon = false;
        })
        .catch(() => {
          emitError('Failed to copy link');
        });
    },

    openBuyNowModal() {
      this.isOpenBuyNow = true;
    },
    closeBuyNowModal() {
      this.refresh();
      this.isOpenBuyNow = false;
    },

    openBidModal() {
      this.isOpenBidModal = true;
    },
    closeBidModal() {
      this.refresh();
      this.isOpenBidModal = false;
    },

    openBidAcceptModal() {
      this.isOpenBidAcceptModal = true;
    },
    closeBidAcceptModal() {
      this.refresh();
      this.isOpenBidAcceptModal = false;
    },

    openBidCancelModal() {
      this.isOpenBidCancelModal = true;
    },
    closeBidCancelModal() {
      this.refresh();
      this.isOpenBidCancelModal = false;
    },

    openUpdateModal() {
      this.isOpenUpdateModal = true;
    },
    closeUpdateModal() {
      this.refresh();
      this.isOpenUpdateModal = false;
    },

    openPublishModal() {
      this.isOpenPublishModal = true;
    },
    closePublishModal() {
      this.refresh();
      this.isOpenPublishModal = false;
    },

    openUnPublishModal() {
      this.isOpenUnPublishModal = true;
    },
    closeUnPublishModal() {
      this.refresh();
      this.isOpenUnPublishModal = false;
    },
  },
};
</script>
<style scoped>
.header {
  height: 35rem;
}
.price {
  height: fit-content;
}
.max-w-80-screen {
  max-width: 80vw;
}
.home-img {
  min-width: 16px;
  min-height: 16px;
}
</style>
