<template>
  <ModalWrapper @close="onClose">
    <div
      class="inline-block align-bottom bg-gray-0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <button class="absolute right-5 top-5" @click="onClose">
        <img src="@/assets/icons/x.svg" alt="close" class="w-8 h-8" />
      </button>
      <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-col  space-y-5">
        <div class="flex items-center text-24">
          Cancel offer
        </div>
      </div>
      <div class="w-full border-b border-blue-450"></div>
      <div
        class="pt-5 pb-4 sm:py-6 text-center flex flex-col items-center space-y-8"
      >
        <div v-if="step === 1" class="w-full flex flex-col space-y-5">
          <div class="text-left px-5 sm:px-10 text-14">
            <p>
              Are you sure you want to cancel your offer
              {{ token.highest_bid }} USDT for {{ token.name }} NFT?
            </p>

            <p>Your funds will be released after confirmation.</p>
          </div>

          <div class="w-full border-b border-blue-450"></div>

          <div class="flex flex-col items-center space-y-4">
            <div class="max-w-max mx-auto">
              <t-button @click.prevent="cancelBid">
                {{ submitLabel }}
              </t-button>
            </div>
          </div>
        </div>

        <ApproveActionModal
          v-else
          :confirmation-id="confirmation_id"
          @close="onClose"
        />
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import * as api from '@/api';

import ModalWrapper from '@/components/modals/ModalWrapper';
import ApproveActionModal from '@/components/modals/ApproveActionModal';
import { emitError } from '@/utils/alerts';

export default {
  name: 'BidCancelModal',
  components: {
    ApproveActionModal,
    ModalWrapper,
  },
  props: {
    token: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      step: 1,
      confirmation_id: '',
    };
  },
  computed: {
    submitLabel() {
      return this.isLoading ? 'Cancelling...' : 'Cancel offer';
    },
  },
  methods: {
    async cancelBid() {
      this.isLoading = true;

      try {
        const { confirmation_id } = await api.post(
          `tokens/${this.token.id}/actions`,
          {
            action_type: 'CANCEL_BID',
          },
        );
        this.confirmation_id = confirmation_id;
        this.step++;
      } catch (e) {
        console.error(e);
        emitError("Couldn't cancel offer. Try again later.");
        this.onClose();
      }
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
