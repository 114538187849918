<template>
  <ModalWrapper @close="onClose">
    <div
      class="inline-block align-bottom bg-gray-0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full relative"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <button class="absolute right-5 top-5" @click="onClose">
        <img src="@/assets/icons/x.svg" alt="close" class="w-8 h-8" />
      </button>
      <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-col space-y-5">
        <div class="flex items-center text-24">
          Buy now
        </div>
      </div>

      <div class="w-full border-b border-blue-450"></div>

      <div
        class="pt-5 pb-4 sm:py-6 text-center flex flex-col items-center space-y-8"
      >
        <form v-if="step === 1" class="w-full flex flex-col space-y-5">
          <div class="space-y-10 px-5 sm:px-10">
            <div class="text-16">
              Total Balance

              <div class="text-32">{{ totalBalance }}</div>
            </div>
            <div class="text-center">
              Price
              <t-input id="price" :value="token.price" type="text" disabled>
              </t-input>
              <p class="text-red-500">{{ error }}</p>
            </div>
          </div>

          <div class="w-full border-b border-blue-450"></div>

          <div class="flex flex-col items-center space-y-4">
            <div class="max-w-max mx-auto">
              <t-button
                v-if="hasfunds"
                ref="buyButton"
                @click.prevent="buyToken"
              >
                Buy now
              </t-button>
              <t-button v-else ref="depositButton" @click="openDepositModal">
                Deposit {{ token.currency }}
              </t-button>
            </div>
          </div>
        </form>
        <ApproveActionModal
          v-else
          :confirmation-id="confirmation_id"
          @close="onClose"
        />
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import * as api from '@/api';

import ModalWrapper from '@/components/modals/ModalWrapper';
import ApproveActionModal from '@/components/modals/ApproveActionModal';
import { formatCrypto } from '@/utils/decimal';

import eventBus from '@/utils/eventBus';

export default {
  name: 'SignInModal',
  components: {
    ApproveActionModal,
    ModalWrapper,
  },
  props: {
    token: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      error: '',
      confirmation_id: '',
      balance: 0,
    };
  },
  computed: {
    totalBalance() {
      return formatCrypto(this.balance.toString(), this.token.currency);
    },
    hasfunds() {
      return this.balance >= this.token.price;
    },
  },
  async mounted() {
    await this.fetchBalance();
    if (this.hasfunds) {
      this.$refs.buyButton.focus();
    } else {
      this.$refs.depositButton.focus();
    }
  },
  methods: {
    async fetchBalance() {
      const balances = await api.get('balances');
      this.balance = parseFloat(balances[this.token.currency]);
    },
    async buyToken() {
      this.error = '';

      try {
        const { confirmation_id } = await api.post(
          `tokens/${this.token.id}/buy`,
        );
        this.confirmation_id = confirmation_id;
        this.step++;
      } catch (e) {
        if (e.response.data) {
          this.error = (e.response.data.non_field_errors || []).join(' ');
        }
        if (e.response.status === 403 && e.response.data.detail) {
          this.error = e.response.data.detail;
        }
      }
    },
    openDepositModal() {
      this.onClose();
      eventBus.$emit('open-deposit-modal', this.token.currency);
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
