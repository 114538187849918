<template>
  <ModalWrapper @close="onClose">
    <div
      class="inline-block align-bottom bg-gray-0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full relative"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <button class="absolute right-5 top-5" @click="onClose">
        <img src="@/assets/icons/x.svg" alt="close" class="w-8 h-8" />
      </button>
      <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="flex items-center text-24">
          {{ headerDisplay }}
        </div>
      </div>
      <div class="w-full border-b border-blue-450"></div>
      <div
        class="px-4 pb-4 sm:p-6 sm:pb-4 text-center flex flex-col items-center"
      >
        <form
          v-if="step === 1"
          class="text-18 px-4 pb-4 sm:p-6 sm:pb-4 space-y-4"
        >
          <div class="text-center">
            <label for="price">
              Price (USDT)
            </label>
            <t-input id="price" ref="price" v-model="price" type="number" />
          </div>
          <div class="inline-block pt-6">
            <t-button
              :disabled="!price || isLoading"
              class="inline-block"
              @click.prevent="updateToken"
            >
              {{ submitLabel }}
            </t-button>
          </div>
        </form>

        <ApproveActionModal
          v-else
          :confirmation-id="confirmation_id"
          @close="onClose"
        />
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import * as api from '@/api';
import { emitError } from '@/utils/alerts';
import ModalWrapper from '@/components/modals/ModalWrapper';
import ApproveActionModal from '@/components/modals/ApproveActionModal';

export default {
  name: 'PublishModal',
  components: {
    ApproveActionModal,
    ModalWrapper,
  },
  props: {
    token: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // form
      price: 1,

      // helpers
      isLoading: false,
      step: 1,
      confirmation_id: '',
    };
  },
  computed: {
    submitLabel() {
      return this.isLoading ? 'Listing...' : 'List for sale';
    },
    headerDisplay() {
      if (!this.token) {
        return 'Sell';
      }
      return `Sell ${this.token.name}`;
    },
  },
  async mounted() {
    this.$refs.price.focus();
    this.price = this.token.price;
  },
  methods: {
    async updateToken() {
      if (!['CREATED', 'SOLD'].includes(this.token.state)) {
        return;
      }
      this.isLoading = true;

      try {
        await api.patch(`tokens/${this.token.id}`, {
          price: this.price,
        });
        const { confirmation_id } = await api.post(
          `tokens/${this.token.id}/actions`,
          {
            action_type: 'PUBLISH_NFT',
          },
        );
        this.confirmation_id = confirmation_id;
        this.step++;
      } catch (e) {
        console.error(e);
        emitError("Couldn't list NFT. Try again later.");
        this.onClose();
      }
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
